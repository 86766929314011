@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'BodoniModa';
    src: url('./assets/fonts/BodoniModa_9pt-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/Assets/Images/noise-texture.png');
  opacity: 0.35;
  pointer-events: none;
  z-index: 9999;
}

@layer components {
  .blurred-gradient-bg {
    position: relative;
    overflow: hidden;
  }

  .blurred-gradient-bg::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle, theme('colors.gradient-start'), theme('colors.gradient-end'));
    filter: blur(40px);
    opacity: 0.7;
    z-index: -1;
  }

  .noise-overlay::after {
    /* Your existing noise overlay styles */
    z-index: 1;
  }
}


